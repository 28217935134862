// Youssef Selkani
// 2019

import React, { Component } from "react";
import "../App.css";
import { Container, Menu, Icon, Button } from "semantic-ui-react";
import fire from "../config/Fire";
import logo from "../assets/logo.png";

export default class DropdownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  logout() {
    fire.auth().signOut();
    window.scrollTo(0, 0);
  }

  render() {
    let { visible, loggedIn } = this.props;
    return (
      <Menu
        borderless
        inverted
        style={{
          border: "none",
          borderRadius: 0,
          boxShadow: "none",
          marginBottom: "0em",
          backgroundColor: "#fff",
          color: "#000",
        }}
      >
        <Container>
          <Menu.Item>
            <img
              src={`${logo}`}
              href="/"
              style={{ width: "40px", margin: "0.7vh" }}
              alt="logo"
            />
          </Menu.Item>
          <Menu.Item header href='/'>
            <span style={{ color: '#000' }}>
              <b>
                C.F. Print
              </b>
            </span>
          </Menu.Item>
          {loggedIn &&
            <Menu.Item header href='/dashboard'>
              <span style={{ color: '#000', fontWeight: '200' }}>
                Dashboard
              </span>
            </Menu.Item>
          }
          {!visible && loggedIn && (
            <Menu.Item
              position="right"
              onClick={() => this.props.handleShowClick()}
            >
              <Icon name="bars" color="black" />
            </Menu.Item>
          )}
          {visible && loggedIn && (
            <Menu.Item
              position="right"
              onClick={() => this.props.handleHideClick()}
            >
              <Icon name="close" color="black" />
            </Menu.Item>
          )}
        </Container>
      </Menu>
    );
  }
}
