// Youssef Selkani
// 2022

import React, { Component } from "react";
import "../App.css";
import { Helmet } from "react-helmet";
import { Segment, Icon, Container, Card } from "semantic-ui-react";

import StoreLinks from '../components/StoreLinks'
import AuthButtons from '../components/AuthButtons'

class Home extends Component {
	render() {
		return (
			<div style={{ backgroundColor: '#f6f6f7' }}>>
				<Container style={{ paddingBottom: "5vh", paddingTop: '5vh', minHeight: '100vh' }}>
					<Helmet><title>{"C.F. Print" + " | Home"}</title></Helmet>

					<h1 style={{ fontSize: "3em" }}>C.F. Print App</h1>
					<p className="mb">C.F Print allows users to retrieve data from private databases and print tickets using bluetooth</p>

					<AuthButtons />
					<StoreLinks />


				</Container>
			</div>
		);
	}
}

export default Home;
