// Youssef Selkani
// 2019

import React, { Component } from 'react'
import fire from '../config/Fire'
import '../App.css'
import { Icon, Button, Input, Message, Segment } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      loading: false
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, edit: true })
  }

  forgetPassword = (e) => {
    const self = this
    self.setState({ loading: true, error: null })
    e.preventDefault()
    let { email } = this.state
    fire
      .auth()
      .sendPasswordResetEmail(email)
      .then(function () {
        self.setState({ loading: false, emailSent: true })
      })
      .catch(function (error) {
        self.setState({ error: error, loading: false })
      })
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render() {
    let { error, emailSent, loading, email } = this.state
    return (
      <div style={{ backgroundColor: '#f6f6f7', minHeight: '100vh' }}>
        <div style={{ backgroundColor: 'black', height: '30vh', marginBottom: '-20vh', }} />
        <Helmet>
          <title>{'Account Recovery' + ' | C.F. Print'}</title>
        </Helmet>
        <center>
          <Segment style={{ alignSelf: 'center', maxWidth: '420px' }}>
            <div className='mtb'>
              <Icon color='black' name='key' size='large' />
            </div>
            <div className='mbs'>
              <h3>Account Recovery</h3>
            </div>
            {error && (
              <Message error className='mb wraptext'>
                {error.message}
              </Message>
            )}
            {emailSent && (
              <Message positive className='mb'>
                <h5>
                  <b>We sent an email to {email} with a link to get back into your account.</b>
                </h5>
              </Message>
            )}
            <div
              style={{
                minWidth: '300px',
                marginTop: '0%',
                marginBottom: '0%'
              }}>
              <div className='mtbs'>
                <Input
                  disabled={emailSent}
                  type='text'
                  fluid
                  onChange={this.handleChange}
                  placeholder='Email'
                  name='email'
                  autoComplete='username'
                />
              </div>
              <Button fluid disabled={emailSent}
                loading={loading}
                onClick={this.forgetPassword}
                style={{ backgroundColor: '#065870', color: '#fff' }}>
                Reset
              </Button>
            </div>
          </Segment>
        </center>
      </div>
    )
  }
}

export default withRouter(ResetPassword)
