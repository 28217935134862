// Youssef Selkani
// 2022

import React, { Component } from 'react'
import '../App.css'
import { Container, Grid, List, Segment, Header, Input, Button } from 'semantic-ui-react'

export default class Footer extends Component {
  render() {
    return (
      <div style={{ maxWidth: '100%', backgroundColor: '#fff' }}>
        <Container >
          <Segment vertical>
            <Grid
              className='footerGrid'
              verticalAlign='top'
              stackable
              centered
              doubling
              relaxed
              stretched
              columns='5'
            >
              <Grid.Row>
                <Grid.Column textAlign='center'>
                  <List.Item href='/pro'>
                    <List.Content>
                      <span style={{ color: '#000' }}>Pricing</span>
                    </List.Content>
                  </List.Item>
                </Grid.Column>
                <Grid.Column textAlign='center'>
                  <List.Item href='/terms'>
                    <List.Content>
                      <span style={{ color: '#000' }}>Terms of Service</span>
                    </List.Content>
                  </List.Item>
                </Grid.Column>

                <Grid.Column textAlign='center'>
                  <List.Item href='/privacy'>
                    <List.Content>
                      <span style={{ color: '#000' }}>Privacy Policy</span>
                    </List.Content>
                  </List.Item>
                </Grid.Column>

                <Grid.Column textAlign='center'>
                  <List.Item href='/refund'>
                    <List.Content>
                      <span style={{ color: '#000' }}>Refund Policy</span>
                    </List.Content>
                  </List.Item>
                </Grid.Column>
                <Grid.Column textAlign='center'>
                  <List.Item href='/contact'>
                    <List.Content>
                      <span style={{ color: '#000' }}>Contact</span>
                    </List.Content>
                  </List.Item>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
        <center>
          <p style={{ backgroundColor: '#000', color: '#eee', padding: '2vh' }}>
            Copyright © 2022 C.F. Print
          </p>
        </center>
      </div>
    )
  }
}