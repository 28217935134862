// Youssef Selkani
// 2022

import React, { Component } from "react";
import "../App.css";
import {
  Button,
  Container,
  Form,
  Message,
  Segment,
} from "semantic-ui-react";
import fire from "../config/Fire";
import Error from "../components/Error";
import ReactJson from "react-json-view";
import { Helmet } from 'react-helmet'

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      message: null,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        let userUnderscore = user.email.replace(/\./g, "_");
        this.setState({
          loggedIn: true,
          email: user.email,
          userUnderscore: userUnderscore,
        });
      } else {
        this.props.history.push("/");
      }
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, edit: true });
  };

  render() {
    let { error, message } =
      this.state;
    return (
      <div style={{ backgroundColor: '#f6f6f7', minHeight: '100vh' }}>
        <div style={{ backgroundColor: '#000', height: '40vh', marginBottom: '-30vh' }} />
        <Helmet>
          <title>{'New App' + ' | C.F. Print'}</title>
        </Helmet>
        <Container style={{ paddingBottom: '4vh' }}>
          <div className="mb center">
            <h2 style={{ color: 'white' }}>New Application</h2>
          </div>
          {error && <Error {...this.state} />}
          <Segment padded='very'>
            <Form>
              {message && (
                <Message color="blue" className="mtb">
                  <p>{message}</p>
                </Message>
              )}

              <Form.Input
                type="text"
                placeholder="MyApp"
                label="Application Name"
                onChange={this.handleChange}
                name="appName"
              />
              <Form.Input
                type="text"
                onChange={this.handleChange}
                label="Quickbase URL"
                placeholder="https://example.quickabase.com/"
                name="url"
              />
              <Form.Input
                type="text"
                onChange={this.handleChange}
                label="Logo URL"
                name="logo"
                placeholder="https://example.com/logo.png"
              />
              <Button disabled size='medium' color="black" onClick={() => this.newApp()}>
                Confirm
              </Button>
            </Form>
            <h3>Instructions</h3>
            <p>To connect a quickabase app</p>
            <ul>
              <li>Open the table you'd like to use</li>
              <li>Click on Settings > Webhooks</li>
              <li>Add New Webhook</li>
            </ul>
            <p>
              <b>Endpoint URL: </b>
              <span>https://cfprint-api.herokuapp.com/endpoint</span>
            </p>
            <p>
              <b>HTTP method : </b>
              <span>POST</span>
            </p>
            <p>
              <b>Message format: </b>
              <span>JSON</span>
            </p>
            <p>
              <b>Message body:</b>
            </p>
            <ReactJson
              displayObjectSize={false}
              displayDataTypes={false}
              src={json}
            />
          </Segment>
        </Container>
      </div>
    );
  }
}

const json = {
  appName: "biolime",
  ticketDate: "[Ticket Date]",
  ticketTime: "[Ticket Time]",
  ticketNumber: "[Ticket No.]",
  source: "[Source]",
  lagoonMaterial: "[Lagoon - Material]",
  lagoon: "[Lagoon]",
  endUser: "[End User]",
  field: "[Field]",
  zone: "[Field - Zone Name]",
  driver: "[Driver]",
  truck: "[Truck #]",
  gallons: "[Gallons]",
};
