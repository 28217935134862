// Youssef Selkani
// 2022

import React, { Component } from 'react'
import '../App.css'
import {
  Container,
  Loader,
  Grid,
  Form,
  Header,
  Label,
  Segment,
  Message,
} from 'semantic-ui-react'
import fire from '../config/Fire'
import { Helmet } from 'react-helmet'

export default class AccountSettings extends Component {
  constructor(props) {
    super(props)
    this.updateProfile = this.updateProfile.bind(this)
    this.state = {
      visible: true,
      loading: true,
      error: false,
      nameEdit: '',
      data: [],
    }
  }
  
  componentDidMount = (event) => {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        window.scrollTo(0, 0)
        this.setState({
          loggedIn: true,
          loading: false,
          ok: true,
          user: user,
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          // emailVerified: user.emailVerified,
          accountType: user.providerData[0].providerId,
          createdAt: user.metadata.creationTime,
          lastSignIn: user.metadata.lastSignInTime
        })
        this.pro()
      } else {
        this.props.history.push(`/`)
      }
    })
  }


  updateProfile = () => {
    const self = this
    self.setState({ updating: true })
    let { nameEdit } = this.state
    var user = fire.auth().currentUser
    user
      .updateProfile({
        displayName: nameEdit
      })
      .then(function () {
        window.location.reload()
      })
      .catch(function (error) {
        self.setState({ error: error })
        console.log(error)
      })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    let {
      email,
      displayName,
      createdAt,
      lastSignIn,
      loading,
      error,
      updating,
    } = this.state
    return (
      <div style={{ backgroundColor: '#f6f6f7', minHeight: '100vh' }}>
        <div style={{ backgroundColor: '#000', height: '30vh', marginBottom: '-20vh' }} />
        <Container>
          <Helmet>
            <title>{'Settings' + ' | C.F. Print'}</title>
          </Helmet>
          {!loading ? (
            <div>
              {error && (
                <Message error size='large'>
                  <b>{error}</b>
                </Message>
              )}
              <Grid stackable verticalAlign='top'>
                <Grid.Column width='8'>
                  <Segment padded='very'>
                    {displayName && <h2>{displayName}</h2>}
                    <Label>{email}</Label>
                    <Form className='mts'>
                      <Form.Input
                        label='Display name'
                        type='text'
                        onChange={this.handleChange}
                        placeholder='John Doe'
                        name='nameEdit'
                      />
                      <Form.Button
                        fluid
                        disabled={error}
                        style={{ backgroundColor: '#065870', color: '#fff' }}
                        loading={updating}
                        onClick={this.updateProfile}
                        content='Update'
                      />
                    </Form>
                  </Segment>
                </Grid.Column>
                <Grid.Column width='4'>
                  <Segment>
                    <Header as='h4'>
                      Last sign in
                      <Header.Subheader>{lastSignIn}</Header.Subheader>
                    </Header>
                  </Segment>
                </Grid.Column>
                <Grid.Column width='4'>
                  <Segment>
                    <Header as='h4'>
                      Created at
                      <Header.Subheader>{createdAt}</Header.Subheader>
                    </Header>
                  </Segment>
                </Grid.Column>
              </Grid>
            </div>
          ) : (
            <div className='mb'>
              <Loader active inline='centered'>
                Loading...
              </Loader>
            </div>
          )}
        </Container>
      </div>
    )
  }
}
