// Youssef Selkani
// 2022

import React, { Component } from "react";
import "../App.css";
import {
  Grid,
  Container,
  Segment,
  Button,
  Loader,
  Form,
  Menu,
  Message,
  Image,
  List,
  Icon,
  Divider,
  Progress
} from "semantic-ui-react";
import fire, { storage } from "../config/Fire";
import { Helmet } from 'react-helmet'
import { InputFile } from 'semantic-ui-react-input-file'

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      message: null,
      user: null,
      customlogo: null,
      edit: false,
      apps: [],
      users: [],
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          loggedIn: true,
          email: user.email,
          uid: user.uid,
        });
        this.userData();
      } else {
        this.props.history.push("/");
      }
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, edit: true });
  };

  userData = (appName) => {
    fetch("https://urchin-app-jypnz.ondigitalocean.app/api/v1/list-users", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((obj) => {
        console.log('obj :', obj);
        if ((obj.code = 200)) {
          var apps = [];
          var raw = obj.data;
          for (let x in raw) {
            apps.push(x);
          }
          this.setState({
            response: obj,
            apps: apps,
            loading: false,
          });
          this.forceUpdate();
          this.LoadApp(apps[0])
          if (appName) {
            this.LoadApp(appName)
          }
        } else {
          this.setState({
            loading: false,
            error: "Internal Server Error |  code 500",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ error: error.message, loading: false });
      });
  };

  LoadApp = (item) => {
    let { response } = this.state;
    if (item) {
      var logo = response.data[item].logo;
      var access = response.data[item].access;
      var users = [];
      for (let x in access) {
        users.push({ email: access[x], id: x });
      }
    } else {
      var item = this.state.appName;
      var logo = response.data[item].logo;
      var access = response.data[item].access;
      var users = [];
      for (let x in access) {
        users.push({ email: access[x], id: x });
      }
    }
    this.setState({
      appName: item || this.state.appName,
      userloading: false,
      edit: true,
      logo: logo,
      users: users,
      active: item,
    });
  };

  addUser = () => {
    this.setState({ userloading: true, message: null });
    let { user, appName } = this.state
    if (this.state.user !== null) {
      fetch("https://urchin-app-jypnz.ondigitalocean.app/api/v1/new-user", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          user: user,
          appName: appName,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((obj) => {
          console.log('obj :', obj.message);
          this.setState({
            message: obj.message,
          });
          this.userData(appName)
        })
        .catch((error) => {
          console.log(error);
          this.setState({ error: error.message, loading: false });
        });
    } else {
      this.setState({ error: "The email address is badly formatted", loading: false, userloading: false });
    }
  };

  linkUser = () => {
    this.setState({ userloading: true, message: null });
    let { user, appName } = this.state
    if (this.state.user !== null) {
      fetch("https://urchin-app-jypnz.ondigitalocean.app/api/v1/link-user", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          user: user,
          appName: appName,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((obj) => {
          this.setState({
            message: obj.message,
          });
          this.userData(appName)
        })
        .catch((error) => {
          console.log(error);
          this.setState({ error: error.message, loading: false });
        });
    } else {
      this.setState({ error: "The email address is badly formatted", loading: false, userloading: false });
    }
  };

  revokeUser = (item) => {
    let { appName } = this.state;
    this.setState({ message: null, userloading: item.email });
    fetch("https://urchin-app-jypnz.ondigitalocean.app/api/v1/revoke-user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        id: item.id,
        appName: appName,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((obj) => {
        this.setState({
          message: obj.message,
          deleting: false
        });
        this.userData(appName)
      })
      .catch((error) => {
        console.log(error);
        this.setState({ error: error.message, loading: false });
      });
  };

  forgetPassword = (item) => {
    const self = this
    self.setState({ error: null, passwordrecovery: item.email })
    fire
      .auth()
      .sendPasswordResetEmail(item.email)
      .then(function () {
        self.setState({ emailSent: true })
      })
      .catch(function (error) {
        self.setState({ error: error.message })
      })
  }

  onDeleteUser = (item) => {
    this.setState({ deleting: item.email });
    fetch("https://urchin-app-jypnz.ondigitalocean.app/api/v1/delete-user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        user_email: item.email,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((obj) => {
        this.revokeUser(item)
        this.setState({
          messageAlt: obj.message,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ error: error.message, loading: false });
      });
  };

  handleFileChange = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0]
      this.setState(() => ({ image, selected: true }))
    }
  }

  handleUpload = () => {
    this.setState({ uploading: true })
    const { image, appName } = this.state
    const uploadTask = storage.ref(`/master/${appName}/assets/logo`).put(image)
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        this.setState({ progress })
      },
      (error) => {
        console.log(error)
        this.setState({ error: error.message })
      },
      () => {
        storage
          .ref(`/master/${appName}/assets/logo`)
          .getDownloadURL()
          .then((imageurl) => {
            this.changeLogo(imageurl)
            this.setState({
              logo: imageurl,
              uploaded: true,
              uploading: false,
              selected: false,
              progress: 0
            })
            setTimeout(() => {
              this.setState({ uploaded: false })
            }, 5000)
          })
      }
    )
  }

  changeLogo = (imageurl) => {
    this.setState({ logoloading: true, message: null, error: null });
    let { appName } = this.state
    fetch("https://urchin-app-jypnz.ondigitalocean.app/api/v1/update-logo", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        appName: appName,
        logo: imageurl,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((obj) => {
        this.setState({
          success: true,
          logoloading: false,
          message: obj.message,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ error: error.message, loading: false });
      });
  };

  handleDismiss = () => {
    this.setState({ error: null, message: null, messageAlt: null, })
  }

  render() {
    let {
      logo,
      loading,
      error,
      apps,
      edit,
      message,
      messageAlt,
      users,
      active,
      logoloading,
      userloading,
      emailSent,
      passwordrecovery,
      deleting,
      uploaded,
      uploading,
      progress,
      selected
    } = this.state;
    return (
      <div style={{ backgroundColor: '#f6f6f7', minHeight: '100vh', paddingTop: '3vh', paddingBottom: '3vh' }}>
        <Helmet>
          <title>{'Dashboard' + ' | C.F. Print'}</title>
        </Helmet>
        <Container style={{ marginBottom: '3vh' }}>

          {!loading &&
            <Menu borderless tabular
            // widths={apps.length}
            // style={{backgroundColor: 'white' }}
            >
              {apps.map((item) => {
                return (
                  <Menu.Item
                    key={item}
                    name={item}
                    active={active === item}
                    onClick={() => this.LoadApp(item)}
                  />
                );
              })}
            </Menu>
          }



            {error && <Message error onDismiss={this.handleDismiss} >Error: {error}</Message>}
            {message && <Message info onDismiss={this.handleDismiss}>{message}</Message>}
            {messageAlt && <Message info onDismiss={this.handleDismiss}>{messageAlt}</Message>}

            {loading ?
              <Loader style={{ marginTop: '3vh' }} active inline='centered' size='small'>
                Loading...
              </Loader>
              :
              <Grid stackable verticalAlign='top'>
                <Grid.Row>
                  <Grid.Column width='8'>
                    {edit &&
                      <Segment padded>
                        <h4>Manage Users</h4>
                        <Form fluid>
                          <Form.Input
                            type="text"
                            icon='user'
                            onChange={this.handleChange}
                            placeholder="Email address"
                            name="user"
                          />
                          <Button.Group fluid>
                            <Button
                              disabled={userloading}
                              style={{
                                backgroundColor: "#000",
                                color: "#fff",
                                marginRight: '1vh'
                              }}
                              onClick={() => this.addUser()}
                            >
                              New User
                            </Button>
                            <Button
                              loading={userloading}
                              disabled={userloading}
                              style={{
                                backgroundColor: '#065870',
                                color: '#fff',
                                borderColor: "#000",
                                borderStyle: "solid",
                                borderWidth: "2px",
                              }}
                              onClick={() => this.linkUser()}
                            >
                              Link User
                            </Button>

                          </Button.Group>
                        </Form>
                      </Segment>
                    }

                    {edit &&
                      <Segment padded>
                        {uploaded && (<Message fluid>The file has been uploaded successfully</Message>)}
                        <Grid columns='2' stackable verticalAlign='middle'>
                          <Grid.Column>
                            <h4>App Logo</h4>
                            <InputFile
                              button='compact basic'
                              input={{
                                accept: 'image/x-png, image/jpeg',
                                id: 'input-control-id2',
                                onChange: this.handleFileChange
                              }}
                            />
                            <Divider />
                            <Button fluid
                              // color='black'
                              onClick={this.handleUpload}
                              disabled={!selected || uploading}
                              loading={loading}>
                              Upload
                            </Button>
                          </Grid.Column>
                          <Grid.Column>
                            <Segment padded='very' basic>
                              {logoloading ?
                                <div className="mtb">
                                  <Loader active inline='centered'>
                                    Loading...
                                  </Loader>
                                </div>
                                :
                                <Image fluid src={logo} centered />}
                            </Segment>
                          </Grid.Column>
                        </Grid>
                        {uploading &&
                          <Segment basic>
                            <Progress percent={progress} active color='black' size='tiny'
                              style={{ marginBottom: 7 }} />
                            Upload in progress ...
                          </Segment>
                        }
                      </Segment>
                    }
                  </Grid.Column>

                  <Grid.Column width='8'>
                    {edit && (
                      <div>
                        {users.length === 0 && <p>Nothing to show here.</p>}
                        <List verticalAlign="middle">
                          {users.map((item) => {
                            return (
                              <Segment padded fluid>
                                {emailSent && passwordrecovery === item.email && <Message info size='small'>Password recovery email sent successfully</Message>}
                                <p>{item.email}</p>
                                <Button
                                  loading={userloading === item.email}
                                  disabled={userloading === item.email}
                                  size="mini"
                                  style={{ backgroundColor: '#f6f6f7' }}
                                  onClick={() => this.revokeUser(item)}
                                >
                                  <Icon name="dont" color="black" />
                                  Revoke Access
                                </Button>
                                <Button
                                  disabled={passwordrecovery === item.email}
                                  size="mini"
                                  style={{ backgroundColor: '#f6f6f7' }}
                                  onClick={() => this.forgetPassword(item)}
                                >
                                  <Icon name="repeat" color="blue" />
                                  Reset Password
                                </Button>
                                <Button
                                  disabled={deleting === item.email}
                                  loading={deleting === item.email}
                                  size="mini"
                                  style={{ backgroundColor: '#f6f6f7' }}
                                  onClick={() => this.onDeleteUser(item)}
                                >
                                  <Icon name="trash alternate outline" color="red" />
                                  Delete User
                                </Button>
                              </Segment>
                            );
                          })}
                        </List>
                      </div>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            }

        </Container>
      </div>
    );
  }
}
