// Youssef Selkani
// 2019

import React, { Component } from 'react'
import '../App.css'
import { Button } from "semantic-ui-react";
export default class AuthButtons extends Component {
    render() {
        return (
            <div>
                <Button
                    style={{
                        backgroundColor: "#065870",
                        color: "white",
                    }}
                    size="large"
                    href="/login"
                >
                    Log in
                </Button>
                <Button
                    basic
                    color="black"
                    size="large"
                    href="/signup"
                >
                    Sign up
                </Button>
            </div>
        )
    }
}
