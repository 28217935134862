// Youssef Selkani
// 2019

import React, { Component } from 'react'
import '../App.css'
import { Message } from 'semantic-ui-react'

export default class Error extends Component {
  render () {
    let { error } = this.props
    return (
      <Message className='mtb' error>
       Error: {error}
      </Message>
    )
  }
}
