import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/auth'
import 'firebase/database'
const config = {
  apiKey:'AIzaSyBkmcbeCROFFz6I9GUPd4F3EJahfr3aD64',
  authDomain: 'biolime-a66cc.firebaseapp.com',
  databaseURL: 'https://biolime-a66cc-default-rtdb.firebaseio.com',
  projectId: 'biolime-a66cc',
  storageBucket: 'biolime-a66cc.appspot.com',
  messagingSenderId: '310573192090',
  appId: '1:310573192090:web:746d13dd8f7078f3d92023',
}
const fire = firebase.initializeApp(config)
const storage = firebase.storage()
export { storage, fire as default }