// Youssef Selkani
// 2019

import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import fire from "./config/Fire";
import "./App.css";
import { createBrowserHistory } from "history";
import { Sidebar, Responsive, Container } from "semantic-ui-react";

//Components
import NavBar from "./components/NavBar";
import SideBarMenuAuth from "./components/SideBarMenuAuth";
import Footer from "./components/Footer";

// Routes
import Home from "./routes/Home";
import LogIn from "./routes/LogIn";
import Signup from "./routes/Signup";
import ResetPassword from "./routes/ResetPassword";
import AccountSettings from "./routes/AccountSettings";
import Dashboard from "./routes/Dashboard";
import NewApp from "./routes/NewApp";

require('dotenv').config()
const history = createBrowserHistory();

class App extends Component {
  constructor(props) {
    super(props);
    this.handleShowClick = this.handleShowClick.bind(this);
    this.state = {
      error: null,
      loading: true,
      maintenance: false,
      unstable: false,
      visible: false,
    };
  }

  handleHideClick = () => this.setState({ visible: !this.state.visible });
  handleShowClick = () => this.setState({ visible: !this.state.visible });
  handleSidebarHide = () => this.setState({ visible: !this.state.visible });

  componentDidMount = () => {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        fire.auth();
        this.setState({
          loggedIn: true,
          loading: false,
          email: user.email,
        });
      } else {
        this.setState({ loggedIn: false, loading: false });
      }
    });
  };

  componentWillMount = (props) => {
    if (window.location.pathname.includes("public")) {
      this.setState({ readonly: true });
    }
  };

  handleOnUpdate = (e, { width }) => this.setState({ width });

  render() {
    let { readonly, loggedIn, visible, loading } = this.state;
    return (
      <Router history={history}>
        {!readonly && (
          <NavBar
            {...this.state}
            handleShowClick={this.handleShowClick.bind(this)}
            handleHideClick={this.handleHideClick.bind(this)}
          />
        )}
        <Responsive fireOnMount onUpdate={this.handleOnUpdate}>
          <Sidebar.Pushable>
            <Sidebar
              animation="push"
              // icon="labeled"
              onHide={this.handleSidebarHide}
              vertical="true"
              direction="right"
              visible={visible}
              style={{
                backgroundColor: "#f6f6f7",
                maxWidth:
                  this.state.width >= Responsive.onlyComputer.minWidth
                    ? "35%"
                    : "100%",
                borderColor: "#f6f6f7",
              }}
            >
              {loggedIn && !loading && <SideBarMenuAuth />}
            </Sidebar>

            <Sidebar.Pusher dimmed={visible}>
              <Route path="/" exact render={() => <Home {...this.state} />} />
              <Route path="/login" component={LogIn} />
              <Route path="/signup" component={Signup} />
              <Route path="/resetpassword" component={ResetPassword} />
              <Route path="/dashboard" component={Dashboard} {...this.state} />
              <Route path="/settings" component={AccountSettings} />
              <Route path="/newapp" component={NewApp} />
            </Sidebar.Pusher>
          </Sidebar.Pushable>
          <Footer {...this.state} />
        </Responsive>
      </Router>
    );
  }
}

export default App;
