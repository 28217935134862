// Youssef Selkani
// 2019

import React, { Component } from "react";
import "../App.css";
import { Menu, Icon, Button, Divider } from "semantic-ui-react";
import fire from "../config/Fire";

export default class SideBarMenuAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  logout() {
    fire.auth().signOut();
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Menu
        tabular
        fluid
        vertical
        borderless
        style={{
          border: "none",
          borderRadius: 0,
          boxShadow: "none",
          marginBottom: "0em",
          backgroundColor: "#f6f6f7",
          color: "#000",
        }}
      >
          <Button fluid href="/settings" basic>
            <Icon name="setting" />
            Settings
          </Button>
          <div className="mts">
          <Button fluid onClick={this.logout} href="/"  basic>
            <Icon name="log out" />
            Log out
          </Button>
        </div>
      </Menu>
    );
  }
}
