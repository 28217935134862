// Youssef Selkani
// 2019

import React, { Component } from 'react'
import '../App.css'
import google from "../assets/google.png";
import apple from "../assets/apple.png";

export default class StoreLinks extends Component {
    render() {
        let { error } = this.props
        return (
            <div className="mt">
                <a
                    href="https://play.google.com/store/apps/details?id=com.usfslk.cfprint"
                    target="_blank"
                >
                    <img
                        src={`${google}`}
                        style={{
                            height: "50px",
                            marginRight: 7,
                        }}
                        alt="logo"
                    />
                </a>
                <a
                    href="https://apps.apple.com/us/app/cfprint/id1629307136"
                    target="_blank"
                >
                    <img
                        src={`${apple}`}
                        href="/"
                        style={{ height: "50px" }}
                        alt="logo"
                    />
                </a>
            </div>
        )
    }
}
