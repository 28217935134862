// Youssef Selkani
// 2022

import React, { Component } from 'react'
import fire from '../config/Fire'
import '../App.css'
import { Form, Icon, Segment, Button } from 'semantic-ui-react'
import { Helmet } from 'react-helmet'

export default class LogIn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      email: '',
      password: '',
      activeItem: 'Log in'
    }
  }

  componentDidMount = () => {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ loggedIn: true, loading: false })
        this.props.history.push('/dashboard')
      } else {
        this.setState({ loggedIn: false, loading: false })
      }
    })
  }

  // Form Handler
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  // Error Handler

  signup = () => {
    const { email, password } = this.state;
    this.setState({ loading: true });
    fire
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((u) => {
        if (u.user) {
          this.props.history.push("/dashboard");
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false, error: error.message });
      });
  };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render() {
    let { error, loading } = this.state
    return (
      <div style={{ backgroundColor: '#f6f6f7', minHeight: '100vh' }}>
        <div style={{ backgroundColor: 'black', height: '30vh', marginBottom: '-20vh', }} />
        <Helmet>
          <title>{'Sign up' + ' | C.F. Print'}</title>
        </Helmet>
        <center
          style={{ backgroundColor: 'transparent' }}>
          <Segment padded='very' style={{ alignSelf: 'center', maxWidth: '420px' }}>
            <center>
              {!loading ? (
                <div className='centerX' style={{ maxWidth: 120, height: 120 }}>
                  <Icon color='black' name='lock' size='large' />
                </div>
              ) : (
                <div className='centerX' style={{ maxWidth: 120, height: 120 }}>
                  <Icon color='black' name='circle notch' size='large' loading />
                </div>
              )}

              <div className='mb'>
                <h3>Create New Account</h3>
              </div>

              {error && (
                <div style={{ color: '#F55E64' }} className='mb'>
                  <h5>
                    <b>{error}</b>
                  </h5>
                </div>
              )}
            </center>
            <div>
              <Form
                style={{
                  minWidth: '300px',
                  marginTop: '0%',
                  marginBottom: '0%'
                }}>
                <Form.Input
                  type='email'
                  onChange={this.handleChange}
                  placeholder='Email'
                  name='email'
                  autoComplete='username'
                />
                <Form.Input
                  type='password'
                  onChange={this.handleChange}
                  placeholder='Password'
                  name='password'
                  autoComplete='current-password'
                />
                <br clear='all' />
                <Button
                  fluid
                  icon
                  labelPosition='left'
                  onClick={this.signup}
                  basic
                  color='black'
                  style={{ marginRight: '5%' }}>
                  <Icon name='sign in' />
                  Sign up
                </Button>
              </Form>
              <div className='mt'>
                <h5>
                  <a href='/login'>Already have an account?</a>
                </h5>
              </div>
            </div>
          </Segment>
        </center>
      </div>
    )
  }
}
